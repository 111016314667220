<template>
  <div class="group-card" :class="{'in-line':inLine}">
    <div class="title">{{ $t(title) }}</div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupCard",
  data() {
    return {}
  },
  props: {
    title: String,
    inLine: Boolean
  },
}
</script>

<style scoped lang='scss'>
.group-card {
  margin-top: 30px;
  margin-bottom: 30px;

  ::v-deep .el-form-item__error {
    margin-left: 130px;
  }

  & + .group-card {
    margin-top: 0;
  }

  &.in-line .content {

    ::v-deep > .group-item {
      display: inline-flex;
      width: 50%;

    }
  }

  .title {
    background: #3296fa;
    color: white;
    padding: 10px 30px;
  }

  .content {
    padding: 45px;
    background: white;
  }

}
</style>