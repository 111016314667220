<template>
<span :style="{width}" class="group-item-input">
    <el-input v-model="dValue" :size="size" :readonly="readonly" :type="type" :autosize="autosize"
              @blur="handleBlur" @focus="handleFocus" :class="clazz" :disabled="disabled"/>
</span>
</template>

<script>
export default {
  name: "GroupItemInput",
  data() {
    return {
      dValue: this.value,
      isBlur: true,
    }
  },
  computed: {
    width() {
      return this.size == "small" ? "90px" : "auto";
    },
    clazz() {
      let data = {};
      if (this.hideBorderOnBlur) {
        data.blur = this.isBlur
      }
      return data;
    }
  },
  watch: {
    dValue(val) {
      this.$emit("input", val)
      this.$emit("update:value", val)
    },
    value(val) {
      this.dValue = val
    },
  },
  props: {
    value: [String, Number],
    size: String,
    readonly: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    hideBorderOnBlur: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleBlur() {
      this.isBlur = true;
    },
    handleFocus() {
      this.isBlur = false;
    },
  },
}
</script>

<style scoped lang='scss'>
.group-item-input{
  &+.group-item-input{
    margin-left: 15px;
  }
}
.blur {
  ::v-deep input {
    border: none;
    cursor: pointer;
  }
}

::v-deep input {
  text-align: center;
  padding: 0;
}
</style>