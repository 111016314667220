<template>
<span class="group-item-column" :style="MinWidth">
  <slot></slot>
</span>
</template>

<script>
export default {
  name: "GroupItemColumn",
  props: {
    minWidth: {
      type: [String, Number],
      default: -1
    },
  },
  computed: {
    MinWidth() {
      let width = this.minWidth;
      if (typeof width === "number") width += "";

      if (this.minWidth == -1) {
        width = "auto"
      } else if(!width.endsWith('px')){
          width+="px"
      }

      return {minWidth: width};
    }
  },
}
</script>

<style scoped lang='scss'>
.group-item-column {

  & + .group-item-column {
    margin-left: 30px;
  }
}
</style>