<template>
  <custom-page>
    <group-card title="custom.page3.部件">
      <el-form :model="form" ref="form" :rules="rules">

        <!--部件类型-->

        <el-form-item>
          <group-item label="custom.page3.部件类型">
            <el-radio-group v-model="form.type">
              <el-radio :label="0">{{ $t("custom.page3.风罩") }}</el-radio>
              <el-radio :label="1">{{ $t("custom.page3.支架") }}</el-radio>
			        <el-radio :label="2">{{ $t("custom.page3.无") }}</el-radio>
            </el-radio-group>
          </group-item>
        </el-form-item>

        <!--风罩-->
        <template v-if="form.type == 0">
          <el-form-item prop="fanDiameter">

            <!--风罩位置-->
            <group-item label="custom.page3.风扇直径">
              <group-item-column>
                <group-item-input v-model.number="form.fanDiameter" size="small" autosize type="number"/>
              </group-item-column>
              <group-item-column>mm</group-item-column>
            </group-item>

          </el-form-item>

          <el-form-item>
            <!--安装风机-->
            <group-item label="custom.page3.安装风机" v-if="!firstUseFan" @change="handleInstallationFanChange">
              <el-switch v-model="form.installationFan"></el-switch>
            </group-item>
          </el-form-item>


          <!--风机个数-->
          <el-form-item prop="fanNum" v-if="!firstUseFan && form.installationFan">
            <group-item label="custom.page3.风机数量">
              <el-select v-model="form.fanNum" size="small">
                <el-option :value="i" v-for="i in 6" :key="i">{{ i }}</el-option>
              </el-select>
            </group-item>
          </el-form-item>

          <!--风机安装孔直径-->
          <el-form-item prop="apertureDiameter" v-if="form.installationFan">
            <group-item label="custom.page3.风机安装孔直径">
              <group-item-column>
                <group-item-input v-model.number="form.apertureDiameter" size="small" autosize type="number"/>
              </group-item-column>
              <group-item-column>mm</group-item-column>
            </group-item>
          </el-form-item>

          <!--拉铆螺母规格-->
          <group-item label="custom.page3.拉铆螺母规格">
            <el-radio-group v-model="form.nut" size="small">
              <el-radio :label="nut" v-for="(nut, i) in source.nut" :key="i">{{ $lang(nut, "title") }}</el-radio>
            </el-radio-group>
          </group-item>
          <sample-image src="Cover-fan.jpg"/>

          <!--风罩位置-->
          <group-item label="custom.page3.风罩位置">
            <el-radio-group v-model="form.fanPosition" size="small">
              <el-radio :label="0">{{ $t("custom.hoodPosition.0") }}</el-radio>
              <el-radio :label="1">{{ $t("custom.hoodPosition.1") }}</el-radio>
            </el-radio-group>
          </group-item>
          <sample-image :src="fanPositionImage"/>

          <!--是否翻边-->
          <group-item label="custom.page3.是否翻边">
            <el-switch v-model="form.isFlanging"></el-switch>
          </group-item>
        </template>

        <!--支架-->
        <template  v-if="form.type == 1">
          <!--支架位置-->
	
          <group-item label="custom.page3.支架位置">
            <el-radio-group v-model="form.bracketLocation" @change="ChangeBracketLocation">
              <el-radio :label="0">{{ $t("custom.bracketPosition.0") }}</el-radio>
              <el-radio :label="1">{{ $t("custom.bracketPosition.1") }}</el-radio>
            </el-radio-group>
          </group-item>
          <sample-image :src="bracketLocationImage"/>

          <!--支架类型-->
          <group-item label="custom.page3.支架类型">
            <table-selct :list="source.bracket" :column-option="bracketOpt" @select="handleBracketSelect"></table-selct>
          </group-item>

        </template>

      </el-form>
    </group-card>

    <div class="action-bar">
      <el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.pre") }}</el-button>
      <el-button type="primary" class="btn" @click="handleNextClick">{{ $t("common.actions.next") }}</el-button>
    </div>
  </custom-page>
</template>

<script>
import CustomPage from "@/views/Custom/components/CustomPage";
import GroupItem from "@/views/Custom/components/GroupItem";
import GroupCard from "@/views/Custom/components/GroupCard";
import mixin from "@/views/Custom/components/mixins/mixins";
import GroupItemInput from "@/views/Custom/components/GroupItemInput";
import GroupItemColumn from "@/views/Custom/components/GroupItemColumn";
import {generatePartOtherObj} from "@/utils";
import {mapGetters} from "vuex";
import SampleImage from "@/views/Custom/components/SampleImage";
import {ORDER_MODULE_MPF_USER} from "../../../../constant";
import TableSelct from "@/views/Custom/components/TableSelct";

export default {
  name: "page3",
  components: {TableSelct, SampleImage, GroupItemColumn, GroupItemInput, GroupCard, GroupItem, CustomPage},
  data() {
    return {
      form: {
        type: 0,
        // 风扇直径
        fanDiameter: null,
        // 是否安装风机
        installationFan: false,
        // 风机数量
        fanNum: 0,
        // 孔直径
        apertureDiameter: null,
        // 螺母
        nut: null,
        // 风罩位置 底部安装、正面安装
        fanPosition: 0,
        // 是否翻边
        isFlanging: true,
        // 支架位置 平行、垂直
        bracketLocation: 0,
        // 支架
        bracket: null,
      },
      source: {
        // 拉铆螺母规格
        nut: [],
        // 支架
        bracket: [],
        // 支架类型
        bracketClazz: [
          {location: 0, type: 0, value: 'CII'},
          {location: 1, type: 0, value: 'CT'},
          {location: 0, type: 1, value: 'PII'},
          {location: 1, type: 1, value: 'PT'},
        ]
      },
      bracketOpt: [
        {title: 'custom.page1.宽', prop: 'width'},
        {title: 'custom.page3.厚', prop: 'thickness'},
        {title: 'custom.page3.孔管距', prop: 'distance'},
      ],
      rules: {
        fanDiameter: [
          {type: 'number', required: true, message: '', trigger: ['blur', 'change']},
          {type: 'number', max: 100, min: 0, message: '', trigger: ['blur', 'change']},
        ],
        fanNum: [
          {type: 'number', required: true, message: '', trigger: ['blur', 'change']},
          {type: 'number', max: 6, min: 1, message: '', trigger: ['blur', 'change']},
        ],
        apertureDiameter: [
          {type: 'number', required: true, message: '', trigger: ['blur', 'change']},
        ],
      },

      // 工况是否选择了风机
      firstUseFan: false,
    }
  },
  mixins: [mixin],
  computed: {
    ...mapGetters(["customInfo", "calcRes"]),
    fanPositionImage() {
      let c = this.form.fanPosition ? "F" : "B";
	  
      return `Cover-AZ-${c}.jpg`
    },
    bracketLocationImage() {
      let p = this.form.bracketLocation ? 'V' : 'P'
      let m = this.module == ORDER_MODULE_MPF_USER ? "MC" : "MCS"
      return `Motn-${m}-${p}.jpg`
    },

  },
  mounted() {
    this.getNutList();
    this.getBracketList();


    // 第一步选择风机曲线 这里必须安装风机，隐藏安装风机选项
    let {work} = this.customInfo;
    let {useDiyFanCurve, fanNum} = work;
    this.firstUseFan = useDiyFanCurve;
    this.form.fanNum = fanNum;
    this.form.installationFan = true;

    // 风扇直径
    this.rules.fanDiameter[0].message = this.$t("tips.notEmpty")
    this.rules.apertureDiameter[0].message = this.$t("tips.notEmpty")
    this.rules.fanNum[0].message = this.$t("tips.notEmpty")
    this.rules.fanNum[1].message = this.$t("custom.page0.风机数量范围")

    let size = this.rules.fanDiameter[1]
    size.max = Math.min(this.calcRes.dLength, this.calcRes.dHeight);
    size.message = this.$t("custom.page3.风扇直径小于等于").format(size.max)
  },
  methods: {
	  ChangeBracketLocation(e){

		  
		  
		this.getBracketList()
	  },
    getNutList() {
      let params = {module: this.module, supplierId: this.customInfo.supplierId}
      this.$req.get("/client/goods/part/matchRivetNut", params).then(res => {
        res.forEach(generatePartOtherObj)
		
		
        this.$set(this.source, "nut", res)
        if (res.length > 0) {
          this.$set(this.form, "nut", res[0])
        }
      })
    },
    getBracketList() {
		
      let params = {
        module: this.module,
        manifoldOD: this.customInfo.collectorTube.otherObj.manifoldOD.value,
        supplierId: this.customInfo.supplierId,
        clazz: this.getBracketClazz()
      }
      this.$req.get("/client/goods/part/matchBracket", params).then(res => {
        res.forEach(generatePartOtherObj)
		
        this.$set(this.source, "bracket", res)
      })
    },

    getBracketClazz() {
		
      let l = this.form.bracketLocation;
      let t = this.type;

      for (const item of this.source.bracketClazz) {
        let {location, value, type} = item
        if (l == location && t == type) return value;
	
      }
    },
    handleBracketSelect(model) {
      this.form.bracket = model
    },
    handleInstallationFanChange() {
      this.$refs.form.clearValidate(["fanNum", "apertureDiameter"])
    },

    async beforeNext() {
      try {
        await this.$refs.form.validate();


        this.$store.commit("SET_CUSTOM_INFO", {page3: this.form})
        return true;
      } catch {
        return false;
      }
    },
  },
}
</script>

<style scoped lang='scss'>

.action-bar {
  text-align: center;

  .btn {
    width: 330px;
  }
}
</style>