<template>
  <div class="page">
    <div class="page-wrapper" :style="{'max-width': maxWidth+'px'}" ref="page">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import constant from "@/utils/constant";

export default {
  name: "CustomPage",
  data() {
    return {
      maxWidth: 1000
    }
  },
  methods:{
    getPageRef(){
      return this.$refs.page
    },
  },
}
</script>

<style scoped lang='scss'>
.page {
  .page-wrapper {
    margin: 0 auto;
    padding-bottom: 30px;
  }
}
</style>