import {getGoodsCustomMode} from "@/utils";

let mixin = {
  data() {
    return {
      _parent: null,
      type: null,
      module: null,
    }
  },
  computed: {
    hasParent() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options.name !== 'CustomPageWrapper') {
          parent = parent.$parent;
        } else {
          this._parent = parent;
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.module = getGoodsCustomMode(this.type)
  },
  methods: {
    async handlePrevClick() {
	
      if (this.hasParent) {
        (await this.beforePrev()) && typeof this._parent.prev === "function" && this._parent.prev();
      }
    },
    async handleNextClick() {
		
	    

		// 选择风机判断
		if(this.windType==1){
			if(!this.form.fanCurve.id){
				this.$message('请选择风机')
				return
			}
		}
		
      if (this.hasParent) {
        (await this.beforeNext()) && typeof this._parent.next === "function" && this._parent.next();
      }else{
		 
		  this.$emit("getInfo",this)
		  
	  }
    },
    beforePrev() {
      return true;
    },
    beforeNext() {
      return true;
    },
  },
}

export default mixin