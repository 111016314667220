<template>
  <div class="group-item">
    <div class="left">
      <div class="label">
        <slot v-if="$slots.label" name="label"></slot>
        <span v-else>{{ $t(label) }}</span>
      </div>
    </div>
    <div class="right">
      <div class="right-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupItem",
  data() {
    return {}
  },
  props: {
    label: String,
  },
}
</script>

<style scoped lang='scss'>
.group-item {
  display: flex;

  & + .group-item {
    margin-top: 15px;
  }

  .left {
    width: 100px;
    margin-right: 30px;

    .label {
      min-height: 30px;
      display: flex;
      align-items: center;
      color: #5C5E62;
      font-size: 14px;
    }
  }

  .right {
    flex: 1;

    .right-content {
      min-height: 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}
</style>