<template>
  <img :src="Src" alt="" class="sample-image">
</template>

<script>
export default {
  name: "SampleImage",
  props: {
    src: String
  },
  computed: {
    Src() {
      return require("@/assets/image/custom/" + this.src);
    }
  },
}
</script>

<style scoped lang='scss'>
.sample-image {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
  margin:30px 0;
}
</style>